import { lazy, Suspense } from "react";
import { motion } from "framer-motion";
import { AnimationSettings, fullPageSpinner } from "../../AnimationSettings";
import { Spin } from "antd";
import { Helmet } from "react-helmet";
const ContactForm = lazy(() => import("../../components/ContactForm"));

const Application = () => {
  return (
    <motion.div {...AnimationSettings}>
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <div className="contact-wrapper">
        <Suspense fallback={<Spin indicator={fullPageSpinner} />}>
          <ContactForm />
        </Suspense>
      </div>
    </motion.div>
  );
};
export default Application;
