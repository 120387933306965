import "./index.css"

const WelcomeCircle = ({ text, className }) => {
    return (
        <div className={`${className} welcome__circle__wrapper`}>
            <div className="welcome__circle__wrapper__inner"></div>
            <span>
                {text}
            </span>
        </div>
    )
}

export default WelcomeCircle