import { useReducer } from "react";
import { createContext } from "react";

const reducer = (state, action) => {
  switch (action.type) {
    case "FOOTER_PASSED":
      return {
        ...state,
        footer_passed: action.payload.footer_passed,
      };
    case "WHITE_SECTION_TEAM":
      return {
        ...state,
        white_section_team: action.payload.white_section_team,
      };
    case "WHITE_SECTION_SERVICES":
      return {
        ...state,
        white_section_services: action.payload.white_section_services,
      };
    case "GET_CV":
      return {
        ...state,
        cv: action.payload.cv,
      };
    case "MODEL_ANIM_FINISHED":
      return {
        ...state,
        model_anim_finished: action.payload.model_anim_finished,
      };
    case "OPEN_MENU":
      return {
        ...state,
        open_menu: action.payload.open_menu
      }
    default:
      return { ...state };
  }
};

const PageContext = createContext();

export const isFooterPassed = (footer_passed, dispatch) => {
  dispatch({
    type: "FOOTER_PASSED",
    payload: {
      footer_passed,
    },
  });
};
export const isWhiteSectionTeam = (white_section_team, dispatch) => {
  dispatch({
    type: "WHITE_SECTION_TEAM",
    payload: {
      white_section_team,
    },
  });
};
export const isWhiteSectionServices = (white_section_services, dispatch) => {
  dispatch({
    type: "WHITE_SECTION_SERVICES",
    payload: {
      white_section_services,
    },
  });
};
export const getCv = (cv, dispatch) => {
  dispatch({
    type: "GET_CV",
    payload: {
      cv,
    },
  });
};
export const isModelAnimFinished = (model_anim_finished, dispatch) => {
  dispatch({
    type: "MODEL_ANIM_FINISHED",
    payload: {
      model_anim_finished,
    },
  });
};
export const isOpenMenu = (open_menu, dispatch) => {
  dispatch({
    type: "OPEN_MENU",
    payload: {
      open_menu
    }
  })
}
const PageContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    showHeader: true,
    courses: [],
    page_passed: false,
    footer_passed: false,
    white_section_team: false,
    white_section_services: false,
    location: "",
    cv: null,
    model_anim_finished: false,
    open_menu: false,
  });

  return (
    <PageContext.Provider value={{ state, dispatch }}>
      {children}
    </PageContext.Provider>
  );
};

export { PageContextProvider, PageContext };
