import "./App.css";
import { Route, Routes } from "react-router-dom";
import Careers from "./Pages/Careers";
import { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import Contact from "./Pages/Contact";
import { useContext } from "react";
import { PageContext } from "./context/Context";
import EstimateProject from "./Pages/EstimateProject";
import { useGetRequest } from "./api/request";
import { GET_URL } from "./api/api";
import CardCarousel from "./components/EstimationQuestions";
import Application from "./Pages/Application";
import PageNotFound from "./Pages/PageNotFound";
import { Helmet } from 'react-helmet';
import Lenis from "@studio-freight/lenis";

// components
const Home = lazy(() => import("./Pages/Home/index"));
const Navbar = lazy(() => import("./components/Navbar"));

function App() {
  const { model_anim_finished } = useContext(PageContext).state;
  const { open_menu } = useContext(PageContext).state
  const location = useLocation();
  const [is404Page, setIs404Page] = useState(false)
  const { getRequest, data } = useGetRequest(GET_URL);
  const memoizedData = useMemo(() => data, [data]);

  const memoizedGetRequest = useCallback(() => {
    let mounted = true;
    if (mounted) {
      getRequest();
    }
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    memoizedGetRequest();
  }, [memoizedGetRequest]);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.clear();
  }, []);
  // Initialize Lenis smooth scroll 
  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      direction: "vertical",
      gestureDirection: "vertical",
      smooth: true,
      mouseMultiplier: 1,
      smoothTouch: false,
      touchMultiplier: 2,
      infinite: false,
    });
    const raf = (time) => {
      lenis.raf(time);
      requestAnimationFrame(raf);
    };
    requestAnimationFrame(raf);
    if(location.pathname.includes('/estimate-project')){
      console.log("destroy lenis")
      lenis.destroy()
    }
  }, [location])
  return (
    <Suspense fallback={null}>
      {!is404Page && <Navbar model_anim_finished={model_anim_finished} />}
      <div
        className={`${open_menu && "App_blurred"} App`}
        id={`${model_anim_finished && "model__animation__finished"}`}
      >
        <Helmet>
          <title>Solvee</title>
        </Helmet>
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <Home
                model_anim_finished={model_anim_finished}
                data={memoizedData}
                setIs404Page={setIs404Page}
              />
            }
          />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/application" element={<Application />} />
          <Route path="/application/:id" element={<Application />} />
          <Route path="/estimate-project" element={<EstimateProject />} />
          <Route path="/estimate-project/questions" element={<CardCarousel />} />
          <Route path="*" element={<PageNotFound setIs404Page={setIs404Page} />} />
        </Routes>
      </div>
    </Suspense>
  );
}

export default App;
