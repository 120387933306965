import {
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  Suspense,
} from "react";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { useFrame, useThree } from "@react-three/fiber";
import { useWindowSize } from "../hooks/useWindowSize";
import { isModelAnimFinished, PageContext } from "../context/Context";

const Model = (props) => {
  const modelRef = useRef();
  const { camera } = useThree();
  const { width } = useWindowSize();
  const context = useContext(PageContext);
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const [fullAnimFinished, setFullAnimFinished] = useState(true);
  const [shouldBeEqualScale, setShouldBeEqualScale] = useState(false);
  const [rot, setRot] = useState(false);
  const [rotate, setRotate] = useState(0);
  const notHomePage = localStorage.getItem("notHomePage");
  const model_anim_finished = localStorage.getItem("model_anim_finished");
  const [scale, scaleCooficent] = useMemo(() => {
    let scale, scaleCooficent;
    if (width < 5000 && width > 1500) {
      scale = 0.24;
      scaleCooficent = 0.0097;
    } else if (width <= 1500 && width > 1200) {
      scale = 0.24;
      scaleCooficent = 0.015;
    } else if (width <= 1200 && width > 800) {
      scale = 0.22;
      scaleCooficent = 0.02;
    } else if (width <= 800 && width > 600) {
      scale = 0.16;
      scaleCooficent = 0.02;
    } else if (width <= 600) {
      scale = 0.15;
      scaleCooficent = 0.02;
    }
    return [scale, scaleCooficent];
  }, [width]);
  const [s, setS] = useState(notHomePage === "true" ? scale + 0.5 : 0);

  const handleMouseMove = useCallback(
    (e) => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      if (props.isAnimated && modelRef.current.scale.x <= 0.24) {
        modelRef.current.rotation.y = (mouseX - window.innerWidth / 2) * 0.0003;
        modelRef.current.rotation.x =
          (mouseY - window.innerHeight / 2) * 0.00018;
      } else if (modelRef.current.scale.x <= 0.24) {
        modelRef.current.rotation.y =
          (mouseX - window.innerWidth / 2) * 0.00003;
        modelRef.current.rotation.x =
          (mouseY - window.innerHeight / 2) * 0.000018;
      }
    },
    [props.isAnimated]
  );

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove]);
  const onScroll = useCallback(() => {
    const scrollY = window.scrollY;
    const rotation = scrollY * 0.0014;
    const scale = 0.24 + scrollY * scaleCooficent * 0.23;
    modelRef.current.rotation.y = rotation;
    modelRef.current.scale.set(scale, scale, scale);
  }, [scaleCooficent]);
  useEffect(() => {
    !props.notHomePage && window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll, props.notHomePage]);

  useEffect(() => {
    let animationId;
    const animateScale = () => {
      const minScale1 = width < 600 ? 0.09 : 0.13;
      const minScale2 = width < 600 ? 0.05 : 0.1;
      if (s < minScale1 && shouldAnimate && model_anim_finished == null) {
        animationId = requestAnimationFrame(() => {
          setS((s) => Math.min(s + 0.005, minScale1));
        });
      }
      if (s === minScale1 && model_anim_finished === null) {
        setShouldAnimate(false);
      }
      if (
        !shouldAnimate &&
        s > minScale2 &&
        fullAnimFinished &&
        model_anim_finished === null
      ) {
        animationId = requestAnimationFrame(() => {
          setS((s) => Math.max(s - 0.001, minScale2));
        });
      }
      setTimeout(() => {
        setFullAnimFinished(false);
        setRot(false);
      }, 3000);
      if (model_anim_finished !== null) {
        setFullAnimFinished(false);
        setRot(false);
      }
      if (s === minScale2) {
        setRot(true);
      }
      if (s < scale && !fullAnimFinished) {
        animationId = requestAnimationFrame(() => {
          model_anim_finished === null
            ? setS((s) => Math.min(s + 0.004, scale))
            : setS((s) => Math.min(scale));
          setShouldBeEqualScale(true);
        });
      }
    };
    if (s === scale) {
      localStorage.setItem("model_anim_finished", true);
      isModelAnimFinished(
        localStorage.getItem("model_anim_finished"),
        context.dispatch
      );
    }
    !props.notHomePage
      ? animateScale()
      : setS(scale + (width < 600 ? 0.3 : 0.5));
    notHomePage === "true" &&
      setInterval(() => {
        setS((prevS) => Math.max(prevS - 0.01, scale));
      }, 50);
    return () => cancelAnimationFrame(animationId);
  }, [
    s,
    shouldAnimate,
    fullAnimFinished,
    rot,
    scale,
    context.dispatch,
    props.notHomePage,
    notHomePage,
    width,
  ]);
  const prevTimeRef = useRef(0);
  const rotationRef = useRef(0);

  useFrame(({ clock }) => {
    modelRef.current.position.x = camera.position.x;
    modelRef.current.position.y = camera.position.y;
    if (rot) {
      if (rotationRef.current === null) {
        rotationRef.current = 0;
        prevTimeRef.current = clock.elapsedTime;
      }
      const time = clock.elapsedTime;
      const deltaTime = time - prevTimeRef.current;
      rotationRef.current += deltaTime * 4;
      modelRef.current.rotation.z = rotationRef.current;
      prevTimeRef.current = time;
    } else if (shouldBeEqualScale || notHomePage === "true") {
      setRotate((rotate) => Math.min(rotate + 0.009, 1.57));
      modelRef.current.rotation.z = clock.elapsedTime * 0.5;
    } else {
      rotationRef.current = null;
    }
  });

  const donut = useGLTF(
    "https://solvee-web-site.s3.eu-west-3.amazonaws.com/3d-models/blue.3d+model.glb"
  );
  return (
    <group {...props} dispose={null} ref={modelRef}>
      {donut.scene.children.map((child) => {
        const materialName = child.material.name;
        const material = donut.materials[materialName];
        return (
          <mesh
            key={child.name}
            geometry={child.geometry}
            material={material}
            position={[props.positionX, props.positionY, props.positionZ]}
            rotation={[props.rotationX, rotate, 3.14]}
            scale={[s, s, s]}
          />
        );
      })}
    </group>
  );
};
const DonutCanvas = ({ notHomePage }) => {
  return (
    <Canvas
      style={{
        position: "fixed",
        height: "100vh",
        pointerEvents: "none",
        zIndex: "200",
      }}
      camera={{ position: [0, 0, 5], zoom: 2.8 }}
    >
      <ambientLight intensity={1} />
      <OrbitControls enableZoom={true} minZoom={1} />
      <spotLight position={[1, 14, 25]} angle={45} />
      <Suspense fallback={null}>
        <Model
          notHomePage={notHomePage}
          positionX={0}
          positionY={0}
          positionZ={0}
          isAnimated={true}
          rotationX={1.57}
          scale={0.24}
        />
      </Suspense>
    </Canvas>
  );
};

export default memo(DonutCanvas);
