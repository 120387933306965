import { memo } from "react";
import WelcomeCircle from "../../components/WelcomeCircle";
import MainButton from "../../components/MainButton";
import { Helmet } from "react-helmet";
import "./index.css";

const EstimateProject = () => {
  return (
    <div className="estimate__project__container page">
      <Helmet>
        <title>Estimate Project</title>
      </Helmet>
      <div className="twoBg__gradient">
        <img src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/new-estimation-first.png" alt="Gradient" />
      </div>
      <h1 className="estimation__title">What You Will Get</h1>
      <div
        className="welcome__circles__wrapper"
        style={{ display: "flex" }}
      >
        <WelcomeCircle
          text="A rough estimation of your project based on the T&M model (man/hours)"
          className="welcome__circle__1"
        />
        <WelcomeCircle
          text="The number of specialists forming your future development team"
          className="welcome__circle__2"
        />
        <WelcomeCircle
          text="A draft architecture design of your solution"
          className="welcome__circle__3"
        />
      </div>
      <MainButton text="START" src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/union.svg" to="/estimate-project/questions" />
    </div>
  );
};

export default memo(EstimateProject);
