import { Spin } from "antd";
import { lazy, Suspense } from "react";
import { motion } from "framer-motion";
import { AnimationSettings, fullPageSpinner } from "../../AnimationSettings";
import { Helmet } from "react-helmet";
const CurrentOpenings = lazy(() => import("../../components/CurrentOpenings"));

const Careers = () => {
  return (
    <motion.div {...AnimationSettings}>
      <Helmet>
        <title>Careers</title>
      </Helmet>
      <Suspense fallback={<Spin indicator={fullPageSpinner} />}>
        <CurrentOpenings />
      </Suspense>
    </motion.div>
  );
};

export default Careers;
