export const POST_ESTIMATION_URL =
  "https://q62gub7k8k.execute-api.eu-west-3.amazonaws.com/v1/estimate";
export const POST_CONTACT_URL =
  "https://vmx1cfv87c.execute-api.eu-west-3.amazonaws.com/v1/apply";
export const PUT_CONTACT_URL =
  "https://vmx1cfv87c.execute-api.eu-west-3.amazonaws.com/v1/solvee-cv-bucket";
export const GET_URL =
  "https://b3as64j9a0.execute-api.eu-west-3.amazonaws.com/v1/team/members";
export const GET_APPLICATIONS_URL =
  "https://b3as64j9a0.execute-api.eu-west-3.amazonaws.com/v1/positions/all";
export const GET_ID_APPLICATIONS_URL =
  "https://b3as64j9a0.execute-api.eu-west-3.amazonaws.com/v1/positions/details/";
