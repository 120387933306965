export const QUESTIONS = [
  {
    id: 1,
    title: "Which platforms will your future software project require?",
    answers: [
      [{ id: 1, iOS: 408 }],
      [{ id: 2, Android: 408 }],
      [{ id: 3, Web: 614 }],
      [{ id: 4, Desktop: 576 }],
    ],
  },
  {
    id: 2,
    title: "Which type of authentication will your solution support",
    answers: [
      [{ id: 5, "Via e-mail / phone number": 155 }],
      [{ id: 6, "Via social networks": 67 }],
      [{ id: 7, "Using 2-step verification": 71 }],
      [{ id: 8, "No Authentication": 0 }],
    ],
  },
  {
    id: 3,
    title: "Which platforms will your future software project require?",
    answers: [
      [
        { id: 9, "Live text-chat": 191 },
        "Users will be able to communicate with each other in real-time using chat.",
      ],
      [
        { id: 10, "Real-time communication": 480 },
        "Peer-to-peer networking will enable users to converse with one another via audio or video.",
      ],
      [
        { id: 11, "User Roles": 90 },
        "User roles are collections of permissions that regulate who has access to what parts and functions of the application.",
      ],
      [
        { id: 12, "Photo upload Gallery": 36 },
        "Users can upload pictures from their libraries or take new ones using the picture upload tool.",
      ],
      [
        { id: 13, "Social features": 39 },
        "Ability to interact with social networks by adding comments, likes, and shares.",
      ],
      [
        { id: 14, "Geolocation services": 390 },
        "Users of geolocation services can locate themselves geographically, view specific locations on a map, set destinations, and determine the distance to those destinations.",
      ],
      [
        { id: 15, "Payments and Purchasing": 296 },
        "With support for credit cards and other important payment methods, the ability to construct intelligent in-app and mobile payment flows.",
      ],
      [
        { id: 16, Localization: 60 },
        "Users may plan events and get a clear picture of their schedule using the calendar tool.",
      ],
      [
        { id: 17, Calendar: 120 },
        "The procedure of converting the app’s language and currency to those of a certain nation or area.",
      ],
    ],
  },
  {
    id: 4,
    title: "How do you see the user interface design of your future solution",
    answers: [
      [
        { id: 18, "Custom Branded UX/UI": 92 },
        "A unique look with recognizable trademark components (such as a logo, corporate colors, fonts, etc.). Beyond the choices offered by templates, designers produce original visual components.",
      ],
      [
        { id: 19, "Animated UX/UI": 160 },
        "A unique design that places a strong emphasis on the interactions that give the interface life.",
      ],
      [
        { id: 20, "I have my Own UI/UX": 0 },
        "To begin the development process, our technical team can get ready-made design mockups.",
      ],
    ],
  },
  {
    id: 5,
    title: "Do you need any third party service integrations?",
    answers: [[{ id: 21, Yes: 240 }], [{ id: 22, No: 0 }]],
  },
  {
    id: 6,
    title: "Do you need an administrative panel for your solution ?",
    answers: [[{ id: 23, Yes: 240 }], [{ id: 24, No: 0 }]],
  },
  {
    id: 7,
    title: "Would you like us to deploy your application?",
    answers: [[{ id: 25, Yes: 0 }], [{ id: 26, No: 0 }]],
  },
];

export const SERVER_BASED_FEATURES = [
  "User Roles",
  "Payments and Purchasing",
  "Real-time communication",
  "Geolocation services",
  "Localization",
]