import { useState } from "react";
import axios from "axios";

export const usePostRequest = (url, data) => {
  const [postLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const postRequest = async () => {
    setLoading(true);
    try {
      await axios.post(url, data);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  return { postRequest, postLoading, error };
};

export const usePutRequest = (url, file) => {
  const [putLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const config = {
    headers: {
      "Content-Type": "application/pdf",
    },
  };
  const putRequest = async () => {
    setLoading(true);
    try {
      await axios
        .put(`${url}/${file.name}`, file, config)
        .then((resp) => setStatus(resp.status));
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  return { putRequest, putLoading, error, status };
};

export const useGetRequest = (url) => {
  const [getLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const getRequest = async () => {
    setLoading(true);
    try {
      await axios.get(url).then((resp) => {
        setData(resp.data.members);
      });
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };
  return { getRequest, getLoading, error, data };
};

export const useGetApplicationsRequest = (url) => {
  const [getLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const getRequest = async () => {
    setLoading(true);
    try {
      await axios.get(url).then((resp) => {
        setData(resp.data);
      });
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };
  return { getRequest, getLoading, error, data };
};

export const useGetApplicationsRequestId = (url) => {
  const [getLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [idData, setIdData] = useState([]);
  const getIDRequest = async (id) => {
    setLoading(true);
    try {
      await axios.get(`${url+id}`).then((resp) => {
        setIdData(resp.data);
      });
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };
  return { getIDRequest, getLoading, error, idData };
};