import "./index.css"
import { useState } from "react"
import MainButton from "../MainButton"

const Results = ({ data, email, setEmail, mobileView, currentQuestionIndex, handleBack, handleNext }) => {
    const totalHours = data.totalHours;
    const {
        developersTotal,
        backendDeveloper,
        frontendDeveloper,
        QADeveloper,
        management,
        DevOps,
        designer,
    } = data.team;
    const getEmail = (e) => setEmail(e.target.value);
    data.userEmail = email;
    const [checkboxData, setCheckboxData] = useState([
        {
            id: 1,
            title: "I would like to have a call about total hours",
            isChecked: false,
        },
        {
            id: 2,
            title: "I allow collecting, processing, using and storing my personal data for the purpose",
            isChecked: false,
        },
    ]);
    const handleCheckbox = (id) => {
        const index = checkboxData.findIndex((checkbox) => checkbox.id === id);
        if (index === -1) {
            return;
        }
        const updatedData = [
            ...checkboxData.slice(0, index),
            {
                ...checkboxData[index],
                isChecked: !checkboxData[index].isChecked,
            },
            ...checkboxData.slice(index + 1),
        ];
        setCheckboxData(updatedData);
    };
    data.userPreference = checkboxData
    return (
        <div className={`results__wrapper__${currentQuestionIndex === 7} results__wrapper`}>
            <div className="first__part">
                <div className="title"><span>Your Results</span></div>
                <div className="project__team"><span>Project Team:</span></div>
                <div className="line"></div>
                <div className="team__title">
                    <div className="main__title">
                        <img src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/new_star.svg" alt="Star" />
                        <span>Developers ({developersTotal} hours)</span>
                    </div>
                    <div className="secondary__title">
                        <span>Frontend Engineer ({frontendDeveloper})</span>
                        <span>Backend Engineer ({backendDeveloper})</span>
                        <span>QA Engineer ({QADeveloper})</span>
                    </div>
                </div>
                {
                    designer !== 0 && (
                        <>
                            <div className="line"></div>
                            <div className="team__title">
                                <div className="main__title">
                                    <img src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/new_star.svg" alt="Star" />
                                    <span>UX/UI ({designer} hours)</span>
                                </div>
                                <div className="secondary__title">
                                    <span>UX/UI Designer ({designer} hours)</span>
                                </div>
                            </div>
                        </>
                    )
                }
                <div className="line"></div>
                <div className="team__title">
                    <div className="main__title">
                        <img src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/new_star.svg" alt="Star" />
                        <span>Management ({management} hours)</span>
                    </div>
                    <div className="secondary__title">
                        <span>Manager ({management}h)</span>
                    </div>
                </div>
                {
                    DevOps !== 0 && (
                        <>
                            <div className="line"></div>
                            <div className="team__title">
                                <div className="main__title">
                                    <img src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/new_star.svg" alt="Star" />
                                    <span>Devops ({DevOps} hours)</span>
                                </div>
                                <div className="secondary__title">
                                    <span>DevOps ({DevOps}h)</span>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
            <div className="second__part">
                <div className="title"><div><span>TOTAL:</span></div><div><span>{totalHours}H</span></div></div>
                <div className="hint__text"><span>Please fill out the short form below to download a detailed estimation of your project including draft cost estimation:
                </span></div>
                <input type="email" placeholder="Email" value={email} onChange={getEmail} />
                <div className="checkbox__wrapper">
                    {
                        checkboxData.map((checkbox, i) => {
                            return (
                                <div key={i} className={`checkbox__${checkbox.isChecked} checkbox`} onClick={() => handleCheckbox(checkbox.id)}>
                                    <div className={`answer__icon__${checkbox.isChecked} answer__icon`}>{
                                        !checkbox.isChecked ? <img src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/plus.svg" alt="Plus Icon" width="10px" height="10px" /> : <div className="answer__active__icon">
                                            <div className="red"></div>
                                        </div>
                                    }</div>
                                    <span>{checkbox.title}</span>
                                </div>
                            )
                        })
                    }
                </div>
                {mobileView && currentQuestionIndex === 7 && (
                    <div className="result__btns__wrapper">
                        <MainButton text="" src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/union-straight-back.svg" className="estimation__back__btn estimation__btn__result" onClick={handleBack} />
                        <MainButton text="Send E-Mail" src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/union-straight.svg" className="estimation__btn__result" onClick={handleNext} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Results