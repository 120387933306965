import { useState, useEffect, useCallback, useMemo, Fragment } from "react";
import { motion } from "framer-motion";
import "./index.css";
import { QUESTIONS, SERVER_BASED_FEATURES } from "../../Pages/EstimateProject/questionsData";
import AnswerBox from "../EstimationAnswerBox";
import MainButton from "../MainButton";
import Results from "../EstimationResults";
import { useMobileSize } from "../../hooks/useMobile";
import Congratulation from "../Congratulation";
import { usePostRequest } from "../../api/request";
import { POST_ESTIMATION_URL } from "../../api/api";
import DonutCanvas from "../../model/Solvee-3d-model"
import { Helmet } from "react-helmet";

const CardCarousel = () => {
    const mobileView = useMobileSize()
    const tabletView = useMobileSize(1100)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
    const [estimationAnswer, setEstimationAnswer] = useState([]);
    const isUserSelected = !estimationAnswer.some(answer => answer.questionIndex === currentQuestionIndex + 1)
    const [nextClicked, setNextClicked] = useState(false);
    const [devOpsAnswer, setDevOpsAnswer] = useState(false)
    const [email, setEmail] = useState("")
    const total = estimationAnswer.reduce((acc, curr) => acc + curr.hours, 0);
    const hasServerBasedFeatures = estimationAnswer.some(answer => SERVER_BASED_FEATURES.includes(answer.name));
    const body = useMemo(() => ({
        team: {
            developersTotal: 0,
            frontendDeveloper: 0,
            backendDeveloper: 0,
            QADeveloper: 0,
            DevOps: 0,
            management: 0,
            designer: 0,
        },
        totalHours: 0,
        userPreference: [],
        userEmail: "",
        diagramType: hasServerBasedFeatures ? "serverBased" : "serverless"
    }), [hasServerBasedFeatures]);
    const updateBody = (developersTotal, frontendDeveloper, backendDeveloper, QADeveloper, DevOps, management, designer, totalHours, body) => {
        body.team = {
            developersTotal,
            frontendDeveloper,
            backendDeveloper,
            QADeveloper,
            DevOps,
            management,
            designer,
        };
        body.totalHours = totalHours
        return body;
    };
    useEffect(() => {
        setDevOpsAnswer(estimationAnswer.find(({ questionIndex }) => questionIndex === 7)?.name === "Yes")
    }, [estimationAnswer])
    const sendServer = useCallback(() => {
        const { hours: designer } = estimationAnswer.find(({ questionIndex }) => questionIndex === 4) || {};
        const specialTotal = total - designer;
        const developersTotal = Math.round(specialTotal * 0.92);
        const frontendDeveloper = Math.round(specialTotal * 0.35);
        const QADeveloper = Math.round(specialTotal * 0.22);
        const management = Math.round(specialTotal * 0.08);
        const devOpsAnswer = estimationAnswer.find(({ questionIndex }) => questionIndex === 7)?.name;
        const devOps = devOpsAnswer === "Yes" ? Math.round(developersTotal * 0.15) : 0;
        const totalHours = developersTotal + management + devOps + designer;
        updateBody(developersTotal, frontendDeveloper, frontendDeveloper, QADeveloper, devOps, management, designer, totalHours, body);
    }, [total, body, estimationAnswer]);
    useEffect(() => {
        if (currentQuestionIndex + 1 === 7) {
            sendServer()
        }
    }, [currentQuestionIndex, sendServer])
    const handleBack = useCallback(() => {
        if (currentQuestionIndex === 0 || currentQuestionIndex === 8) return
        setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }, [currentQuestionIndex]);
    const { postRequest } = usePostRequest(
        POST_ESTIMATION_URL,
        body
    );
    const isEmailEmpty = email !== "" && email.includes("@") && email.includes(".")
    const handleNext = useCallback(() => {
        if (currentQuestionIndex > -1 && ((isUserSelected && currentQuestionIndex < 7) || (!isEmailEmpty && currentQuestionIndex === 7))) {
            setNextClicked(true);
            return;
        } else if (currentQuestionIndex === 8) return
        else {
            currentQuestionIndex === 7 && postRequest()
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
            setNextClicked(false);
        }
    }, [isUserSelected, currentQuestionIndex, isEmailEmpty, postRequest]);
    const noAuthentication = estimationAnswer.find(answer => answer.id === 8);
    const withoutAuthentication = estimationAnswer.some(answer => answer.id === 5 || answer.id === 6 || answer.id === 7)
    const handleAnswer = (id, hours, name) => {
        if (currentQuestionIndex > 2) {
            // Only allow one answer for question > 2
            const prevAnswer = estimationAnswer.find(
                (a) => a.questionIndex === currentQuestionIndex + 1
            );
            if (prevAnswer) {
                if (prevAnswer.id === id) {
                    // Deselect the answer if it was already selected
                    setEstimationAnswer((prev) =>
                        prev.filter((a) => a.questionIndex !== currentQuestionIndex + 1)
                    );
                    return;
                } else {
                    // Replace the previously selected answer with the new one
                    setEstimationAnswer((prev) =>
                        prev.map((a) =>
                            a.questionIndex === currentQuestionIndex + 1 ? { id, hours, name, questionIndex: currentQuestionIndex + 1 } : a
                        )
                    );
                    return;
                }
            }
            setEstimationAnswer((prev) => [
                ...prev,
                { id, hours, name, questionIndex: currentQuestionIndex + 1 },
            ]);
        } else {
            if ((noAuthentication && (id === 5 || id === 6 || id === 7)) || (withoutAuthentication && (id === 8))) return;
            // Allow multiple answers for other questions
            const answerIndex = estimationAnswer.findIndex((a) => a.id === id);
            if (answerIndex >= 0) {
                const updatedAnswer = estimationAnswer.filter((a) => a.id !== id);
                setEstimationAnswer(updatedAnswer);
            } else {
                setEstimationAnswer((prev) => [
                    ...prev,
                    { id, hours, name, questionIndex: currentQuestionIndex + 1 },
                ]);
            }
        }
        setNextClicked(false);
    };
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.code === "ArrowLeft") {
                handleBack();
            } else if (e.code === "ArrowRight") {
                handleNext();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [currentQuestionIndex, handleNext, handleBack]);
    useEffect(() => {
        isEmailEmpty && setNextClicked(false)
    }, [isEmailEmpty])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="estimation__question__wrapper">
            <Helmet>
                <title>Estimate Project</title>
            </Helmet>
            <div className="questions__gradient" style={{
                opacity: (currentQuestionIndex === -1 || currentQuestionIndex === 8) ? 0 : 1
            }}>
                <img src={tabletView ? "https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/new-congratulationss.png" : "https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/new-question.png"} alt="Gradient" />
            </div>
            <div className="card__carousel">
                {currentQuestionIndex > -1 && QUESTIONS.map((question, index) => {
                    const distanceFromActive = index - currentQuestionIndex;
                    let x = 0;
                    let y = 0;
                    if (distanceFromActive === 0) {
                        x = 0;
                    } else if (distanceFromActive === 1) {
                        x = 120;
                        y = 8.5;
                    } else if (distanceFromActive === -1) {
                        if (currentQuestionIndex === 7) x = -300
                        else {
                            x = -120;
                            y = 8.5
                        }
                    } else {
                        x = distanceFromActive * 100;
                    }
                    return (
                        <motion.div
                            key={index} 
                            style={{
                                position: mobileView ? "absolute" : "fixed",
                                top: "50%",
                                left: "50%",
                                transform: `translateY(${y}%) translateX(${x}%) rotate(${distanceFromActive === -1
                                    ? "-8deg"
                                    : distanceFromActive === 1
                                        ? "8deg"
                                        : "0deg"
                                    })`,
                                opacity: Math.max(0.1, 1 - 0.2 * Math.abs(distanceFromActive)),
                            }}
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: 1,
                                transform: currentQuestionIndex === 7 ? `translateY(100%)` : `translate(-50%, -50%) translateY(${y}%) translateX(${x}%) rotate(${distanceFromActive === -1
                                    ? "-8deg"
                                    : distanceFromActive === 1
                                        ? "8deg"
                                        : "0deg"
                                    })`,
                            }}
                            exit={{ opacity: 0 }}
                            transition={{
                                duration: currentQuestionIndex === 7 ? 1 : 0.5,
                                ease: currentQuestionIndex === 7 ? "easeIn" : [0.42, 0, 0.58, 1],
                            }}
                            className={`question__wrapper__${!!distanceFromActive} question__wrapper`}
                        >
                            {
                                !mobileView && <div className="question__wrapper__bg">
                                    <img src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/question-wrapper.svg" alt="Background" />
                                </div>
                            }
                            <div className="question__number">
                                <span>
                                    Question {question.id} from {QUESTIONS.length}
                                </span>
                            </div>
                            <div className="question__info">
                                <div className="question">
                                    <span>{question.title}</span>
                                </div>
                                <div className="question__time">
                                    <div className="time">
                                        <span>{devOpsAnswer ? body.totalHours : total}H</span>
                                    </div>
                                    <div className="estimation__time">
                                        <div>
                                            <span className="whitespace"></span>Time
                                        </div>
                                        <div>Estimation</div>
                                    </div>
                                </div>
                            </div>
                            <div className="answers__wrapper">
                                {question?.answers?.map((answer, i) => {
                                    const answerTitle = Object.keys(answer[0])[1];
                                    const answerDesc = answer[1] && answer[1];
                                    const answerId = Object.values(answer[0])[0];
                                    const hours = Object.values(answer[0])[1];
                                    const isActive =
                                        estimationAnswer.findIndex((a) => a.id === answerId) >= 0;
                                    return (
                                        <Fragment key={i}>
                                            <AnswerBox
                                                text={answerTitle}
                                                desc={answerDesc}
                                                handleAnswer={() =>
                                                    handleAnswer(answerId, hours, answerTitle)
                                                }
                                                isActive={isActive}
                                            />
                                        </Fragment>
                                    );
                                })}
                            </div>
                        </motion.div>
                    );
                })}
            </div> 
            {
                currentQuestionIndex === -1 && <Congratulation btnText="Start a project" text1="Your project is going to take some" text2="time, budget and professional effort." text3="Let’s estimate how much of each it" text4="will cost by taking this quiz" estimateStart={true} onClick={handleNext} className="estimate__start__title" />
            }
            {
                currentQuestionIndex > -1 && currentQuestionIndex < 8 && <Results data={body} email={email} setEmail={setEmail} mobileView={mobileView} currentQuestionIndex={currentQuestionIndex} handleBack={handleBack} handleNext={handleNext} />
            }
            {
                currentQuestionIndex === 8 && <Congratulation text1="Congratulations!" hint="We have sent the project estimation file to your mailbox !" />
            }
            {
                ((mobileView && currentQuestionIndex !== -1 && currentQuestionIndex < 7) || (!mobileView)) &&
                <div className="estimation__btns__wrapper">
                    {((currentQuestionIndex > 0 && currentQuestionIndex < 8 && !mobileView) || (currentQuestionIndex > -1 && currentQuestionIndex !== 7 && currentQuestionIndex !== 8 && mobileView)) && (
                        <MainButton
                            text="Back"
                            className="estimation__back__btn"
                                src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/union-straight-back.svg"
                            onClick={handleBack}
                        />
                    )}
                    {
                        ((currentQuestionIndex > -1 && currentQuestionIndex < 8 && !mobileView) || (currentQuestionIndex !== -1 && currentQuestionIndex !== 7 && currentQuestionIndex !== 8 && mobileView)) && (
                            <MainButton
                                text={`${currentQuestionIndex >= QUESTIONS.length - 1 ? `${currentQuestionIndex === 7 ? "Send E-Mail" : "Get Estimation"}` : "Next"}`}
                                className={`estimation__next__btn__${((isUserSelected && currentQuestionIndex < 7) || (currentQuestionIndex === 7 && !isEmailEmpty)) && "disabled"} estimation__next__btn`}
                                    src={((isUserSelected && currentQuestionIndex < 7) || (currentQuestionIndex === 7 && !isEmailEmpty)) ? "https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/union-straight-disabled.svg" : "https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/union-straight.svg"}
                                onClick={handleNext}
                            />
                        )
                        }
                    </div>
            }
            {
                currentQuestionIndex !== -1 && currentQuestionIndex !== 8 && (
                    <div className={`tooltip__error__${nextClicked} ${currentQuestionIndex === 7 && mobileView && "tooltip__error__7"} tooltip__error`}>
                        <img src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/tooltip-error.svg" alt="Tooltip error" />
                        <span>{currentQuestionIndex < 7 ? "Choose at least one item" : "Write your email"}</span>
                    </div>
                )
            }
            <div className="estimation_3d" style={{ opacity: currentQuestionIndex === 8 ? 1 : 0 }}>
                <DonutCanvas notHomePage={true} />
            </div>
        </div>
    );
};

export default CardCarousel;