import "./index.css"
import { useEffect } from "react";
import Lenis from "@studio-freight/lenis";
import imagesLoaded from "imagesloaded";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import GradientModel from "../../model/gradient-model";
import { useMobileSize } from "../../hooks/useMobile";
import MainButton from "../../components/MainButton";

const PageNotFound = ({ setIs404Page }) => {
    const mobileView = useMobileSize()
    const repeatItems = (parentEl, total = 0) => {
        const items = [...parentEl.children];
        for (let i = 0; i <= total - 1; ++i) {
            var cln = items[i].cloneNode(true);
            parentEl.appendChild(cln);
        }
    };
    useEffect(() => {
        setIs404Page(true)
    }, [setIs404Page])
    useEffect(() => {
        const lenis = new Lenis({
            smooth: true,
            infinite: true
        });

        lenis.on('scroll', () => {
            ScrollTrigger.update();
        });

        const raf = (time) => {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        gsap.registerPlugin(ScrollTrigger)
        imagesLoaded(document.querySelectorAll('.grid__item'), { background: true }, () => {
            document.body.classList.remove('loading');

            repeatItems(document.querySelector('.grid'), 1);

            const items = [...document.querySelectorAll('.grid__item')];
            const firtsItem = items[0];
            gsap.set(firtsItem, { transformOrigin: '50% 100%' })
            gsap.to(firtsItem, {
                ease: 'none',
                startAt: { scaleY: 1 },
                scaleY: 0,
                scrollTrigger: {
                    trigger: firtsItem,
                    start: 'center center',
                    end: 'bottom top',
                    scrub: true,
                    fastScrollEnd: true,
                    onLeave: () => {
                        gsap.set(firtsItem, { scaleY: 1, })
                    },
                }
            });

            // last item  
            const lastItem = items[2];
            gsap.set(lastItem, { transformOrigin: '50% 0%', scaleY: 0 })
            gsap.to(lastItem, {
                ease: 'none',
                startAt: { scaleY: 0 },
                scaleY: 1,
                scrollTrigger: {
                    trigger: lastItem,
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: true,
                    fastScrollEnd: true,
                    onLeaveBack: () => {
                        gsap.set(lastItem, { scaleY: 1 })
                    }
                }
            });
            let ft;
            let st;
            const middleItem = items[1];

            ft = gsap.timeline()
                .to(middleItem, {
                    ease: 'none',
                    onStart: () => {
                        if (st) st.kill()
                    },
                    startAt: { scale: 0 },
                    scale: 1,
                    scrollTrigger: {
                        trigger: middleItem,
                        start: 'top bottom',
                        end: 'center center',
                        scrub: true,
                        onEnter: () => gsap.set(middleItem, { transformOrigin: '50% 0%' }),
                        onEnterBack: () => gsap.set(middleItem, { transformOrigin: '50% 0%' }),
                        onLeave: () => gsap.set(middleItem, { transformOrigin: '50% 100%' }),
                        onLeaveBack: () => gsap.set(middleItem, { transformOrigin: '50% 100%' }),
                    },
                });

            st = gsap.timeline()
                .to(middleItem, {
                    ease: 'none',
                    onStart: () => {
                        if (ft) ft.kill()
                    },
                    startAt: { scale: 1 },
                    scale: 0,
                    scrollTrigger: {
                        trigger: middleItem,
                        start: 'center center',
                        end: 'bottom top',
                        scrub: true,
                        onEnter: () => gsap.set(middleItem, { transformOrigin: '50% 100%' }),
                        onEnterBack: () => gsap.set(middleItem, { transformOrigin: '50% 100%' }),
                        onLeave: () => gsap.set(middleItem, { transformOrigin: '50% 0%' }),
                        onLeaveBack: () => gsap.set(middleItem, { transformOrigin: '50% 0%' }),
                    },
                });

            requestAnimationFrame(raf);

            const refresh = () => {
                ScrollTrigger.clearScrollMemory();
                window.history.scrollRestoration = 'manual';
                ScrollTrigger.refresh(true);
            }

            refresh();
            window.addEventListener('resize', refresh);
        });
    }, []);
    return (
        <div className="pageNotFound">
            <div className="gradient3D_404">
                <GradientModel bg={mobileView ? "https://solvee-web-site.s3.eu-west-3.amazonaws.com/3d-models/new-hero-mobile.png" : "https://solvee-web-site.s3.eu-west-3.amazonaws.com/3d-models/new_hero.png"} />
            </div>
            <div className="top">
                <MainButton text="HOME PAGE" src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/union.svg" to="/" />
            </div>
            <div className="grid">
                <div className="grid__item grid__item--stack">
                    <div className="grid__item-logo">
                        404
                    </div>
                </div>
                <div className="grid__item">
                    <div className="grid__item-inner">
                        <div className="grid__item-img"></div>
                    </div>
                </div>
            </div>
            <div className="btm">
                <span>ERROR</span>
                <span>PAGE NOT FOUND</span>
            </div>
        </div>
    )
}

export default PageNotFound