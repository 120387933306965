import { lazy, Suspense } from "react";
import { motion } from "framer-motion";
import { AnimationSettings, fullPageSpinner } from "../../AnimationSettings";
import { Spin } from "antd";
const CurrentOpenings = lazy(() => import("../../components/CurrentOpenings"));

const Contact = () => {
  return (
    <motion.div {...AnimationSettings}>
      <div className="contact-wrapper">
        <Suspense fallback={<Spin indicator={fullPageSpinner} />}>
          <CurrentOpenings />
        </Suspense>
      </div>
    </motion.div>
  );
};
export default Contact;
