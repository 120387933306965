import "./index.css"

const AnswerBox = ({ handleAnswer, text, desc, isActive }) => {
    return (
        <div className={`${!desc && "answer__box__without__desc"} answer__box__wrapper__${isActive} answer__box__wrapper`} onClick={handleAnswer}>
            <div className="answer__info">
                <div className="answer__title">{text}</div>
                <div className="answer__icon">{
                    !isActive ? <img src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/plus.svg" alt="Plus Icon" width="10px" height="10px" /> : <div className="answer__active__icon">
                        <div className="red"></div>
                    </div>
                }</div>
            </div>
            <div className="answer__desc">
                <span>{desc}</span>
            </div>
        </div>
    )
}

export default AnswerBox