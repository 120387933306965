import { useRef, useMemo } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import * as THREE from "three";
import { useWindowSize } from "../hooks/useWindowSize";

const loader = new THREE.TextureLoader();
const clock = new THREE.Clock();

const MeshComponent = ({ meshRef }) => {
  useFrame(() => {
    const mesh = meshRef.current;
    const time = clock.getElapsedTime();
    const positions = mesh.geometry.attributes.position.array;
    const count = positions.length / 3;
    const positionNeedsUpdate = mesh.geometry.attributes.position.needsUpdate;

    for (let i = 0; i < count; i++) {
      const x = positions[i * 3];
      const y = positions[i * 3 + 1];
      const anim1 = 0.25 * Math.sin(x + time * 0.7);
      const anim2 = 0.35 * Math.sin(x + time * 0.7);
      const anim3 = 0.1 * Math.sin(y * 15 + time * 0.7);
      positions[i * 3 + 2] = anim1 + anim2 + anim3;
    }

    if (!positionNeedsUpdate) {
      mesh.geometry.attributes.position.needsUpdate = true;
    }
  });

  return null;
};

const GradientModel = ({ bg }) => {
  const { width } = useWindowSize();
  const [scale, scaleCoefficient] = useMemo(() => {
    let scale, scaleCoefficient;

    if (width < 5000 && width > 1500) {
      scale = 9.5;
      scaleCoefficient = 2;
    } else if (width <= 1500 && width > 600) {
      scale = 7.5;
      scaleCoefficient = 3;
    } else {
      scale = 4;
      scaleCoefficient = 1;
    }

    return [scale, scaleCoefficient];
  }, [width]);

  const meshRef = useRef();

  return (
    <Canvas
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        pointerEvents: "none",
      }}
      camera={{ position: [0, 0, 5], zoom: 2.8 }}
    >
      <ambientLight intensity={5} />
      <mesh ref={meshRef}>
        <planeGeometry args={[scale, 6, scaleCoefficient, 3]} />
        <meshBasicMaterial map={loader.load(bg)} />
      </mesh>
      <Html>
        <div className="gradient__model" />
      </Html>
      <MeshComponent meshRef={meshRef} />
    </Canvas>
  );
};

export default GradientModel;
