import "./index.css"
import MainButton from "../MainButton"
import { useMobileSize } from "../../hooks/useMobile";

const Congratulation = ({ text1, text2, text3, text4, hint, btnText, estimateStart, onClick, className }) => {
    const handleClick = () => {
        localStorage.setItem("notHomePage", "true");
    };
    const mobileView = useMobileSize()
    return (
        <>
        <div className="congratulations__wrapper">
            <div className="twoBg__gradient">
                <img src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/new-congratulationss.png" alt="Gradient" />
            </div>
            {
                estimateStart
                    ?
                    <div className="congratulation__circle">
                        <div className="clock"></div>
                    </div>
                    :
                    <div className="congratulation__circle">
                        <img src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/tick.svg" alt="Tick" width="20px" height="20px" />
                    </div>
            }
            <div className={`${className} estimation__title`}>
                <div>{text1}</div>
                <div>{text2}</div>
                <div>{text3}</div>
                <div>{text4}</div>
            </div>
            <div className="mail__hint__text">
                <span>{hint}</span>
            </div>
            {!mobileView && <MainButton text={btnText ? btnText : "HOME PAGE"} src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/union.svg" className="congratulation__btn" to={!estimateStart ? "/" : ""} onClick={estimateStart ? onClick : handleClick} />}
        </div>
        {mobileView && <MainButton text={btnText ? btnText : "HOME PAGE"} src="https://solvee-web-site.s3.eu-west-3.amazonaws.com/Solvee-images/new/union.svg" className="congratulation__btn" to={!estimateStart ? "/" : ""} onClick={estimateStart ? onClick : handleClick} />}
        </>
    )
}

export default Congratulation