import { LoadingOutlined } from "@ant-design/icons";

export const AnimationSettings = {
  transition: { duration: 0.5 },
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};

export const animatedText = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const spinner = (
  <LoadingOutlined
    style={{
      fontSize: 45,
      color: "#fff",
    }}
    spin
  />
);

export const fileUploadedSpinner = (
  <LoadingOutlined
    style={{
      fontSize: 30,
      color: "#fff",
    }}
    spin
  />
);

export const fullPageSpinner = (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#00000088",
      width: "100vw",
      height: "100vh",
      zIndex: 9999,
    }}
  >
    <LoadingOutlined
      style={{
        fontSize: 45,
        color: "#fff",
      }}
    />
  </div>
);
