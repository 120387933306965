import { useState, useEffect, memo } from "react";
import { HashLink } from "react-router-hash-link";
import { gsap } from "gsap";
import "./index.css";

const MainButton = ({ to, text, onClick, src, className }) => {
  const [degree, setDegree] = useState(92.01);
  const animateDegree = () => {
    const degrees = [360];
    const nextDegree = degrees[(degrees.indexOf(degree) + 1) % degrees.length];
    setDegree(nextDegree);
    requestAnimationFrame(animateDegree);
  };
  useEffect(() => {
    requestAnimationFrame(animateDegree);
    gsap.to(".start__project__btn", {
      duration: 10,
      backgroundImage: `linear-gradient(${degree}deg, #110173 -4.01%, #3850CE 100.67%)`,
      ease: "power1.ease",
      repeat: -1,
      yoyo: true,
    });

    return () => {
      cancelAnimationFrame(animateDegree);
    };
  }, [degree]); // eslint-disable-line

  return (
    <HashLink onClick={onClick} to={to} className={`${className} start__project__btn`}>
      <div className="glow-wrap">
        <i className="glow"></i>
      </div>
      <div>
        <img
          src={src}
          alt="Start Project"
          width="18px"
          height="18px"
          className="start__project__btn__img"
        />
        <p>{text}</p>
        <img
          src={src}
          alt="Start Project"
          width="18px"
          height="18px"
          className="start__project__btn__img"
        />
      </div>
    </HashLink>
  );
};

export default memo(MainButton);
